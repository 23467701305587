import React, { useState, useEffect } from 'react';
import {
  Button,
  Divider,
  Select,
  Table,
  Row,
  Col,
  Form,
  Collapse,
  Space,
  Typography,
  Upload,
  notification,
  Input,
  Tooltip,
  List,
} from 'antd';
import { Modal, Steps } from 'antd';
import { download } from '../services/auth';
import {
  SendOutlined,
  UploadOutlined,
  DownloadOutlined,
  AlertTwoTone,
  FilterOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import { getContractorOnboard, getCloudInfo } from '../services/auth';
import { Email } from '../services/auth';

import { epf, epfIteartion } from '../services/auth';
import axios from '../services/axiosInstance';
import useAuth from '../hooks/useAuth';
import { encrypt } from '../utils/crypto';
import { color } from 'echarts';
const { Option } = Select;
const { Panel } = Collapse;
const { Title } = Typography;
const { Step } = Steps;

function Pfreport() {
  const [modalVisible, setModalVisible] = useState(false);
  const [Employee, setEmployee] = useState([]);
  const [Employee1, setEmployee1] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = React.useState({});
  const { userConfig } = useAuth();
  const [form] = Form.useForm();
  const [Request, setRequest] = useState({});
  const [transactionId, setTransactionId] = useState('');
  const [currentStep, setCurrentStep] = useState(0);
  const [currentStepIssues, setCurrentStepIssues] = useState(0);

  const [reportData, setReportData] = useState(null);
  const [isExceptionRecord, setIsExceptionRecord] = useState(false);
  const [selectedDropdown1, setSelectedDropdown1] = useState('PDF');
  const [selectedDropdown2, setSelectedDropdown2] = useState(null);
  const [exceptionRecords, setExceptionRecords] = useState(false);
  const [excepEmployeeRecords, setExcepEmployeeRecords] = useState([]);
  const [noMatchRecords, setNoMatchRecords] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState('');
  const [fileExplorerVisible, setFileExplorerVisible] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [contractorFilters, setContractorFilters] = useState([]);
  const getPopupContainer = (triggerNode) => triggerNode.parentNode;

  const roleName = userConfig.roleName;

  const onKronosFileUpload = (file) => {
    form.setFieldsValue({ kronosFile: file });
  };

  const onPortalFileUpload = (file) => {
    form.setFieldsValue({ portalFile: file });
  };
  const { Item } = Form;
  React.useEffect(() => {
    if (selectedDropdown2 !== null) {
      EmployeeData();
    }
  }, [selectedDropdown2]);

  const onFinish = (value) => {
    const { kronosFile, portalFile, supplierId } = value;
    console.log('76', portalFile.file);

    // Split the supplierId string at the comma
    const [supplierIdValue, supplierName] = supplierId.split(',');

    const formData = new FormData();
    formData.append('epfKronos', kronosFile.file);
    formData.append('acceptableDifference', value?.acceptableDifference);
    formData.append('month', value?.month);

    formData.append('epfPortal', portalFile.file);
    formData.append('orgId', userConfig.orgId);
    formData.append('supplierId', supplierIdValue); // Use "supplierIdValue" for the supplierId value
    formData.append('supplierName', supplierName); // Use "supplierName" for the supplierName value
    const dateString = portalFile.file.name.split('_')[1];

    if (!dateString) {
      notification.warning({ message: 'Plz upload valid PF_ECR file' });
    }

    const date = getDate(dateString.slice(0, 8));
    if (!date) {
      notification.warning({ message: 'Plz upload valid PF_ECR file' });
    }
    formData.append('paymentDate', date);
    epf(formData)
      .then((res) => {
        const transactionId1 = res.data.transactionId;
        setRequest({ transactionId1 });
        notification.success({
          message: 'PF Exception Report successfully generated',
        });
      })
      .catch((err) => {
        notification.error({ message: err.response.data.message });
      });

    setModalVisible(true);
  };

  const [file, setFile] = useState(null); // State to hold the uploaded file

  const updateAndValidate = (value) => {
    const formData = new FormData();
    // formData.append('epfKronos', kronosFile.file);
    formData.append('acceptableDifference', value?.acceptableDifference);
    // formData.append('month', value?.month);

    formData.append('epfPortal', file);
    formData.append('orgId', userConfig.orgId);
    formData.append('supplierId', reportData?.data?.supplierId);
    formData.append('supplierName', reportData?.data?.supplierName);
    const dateString = file.name.split('_')[1];

    if (!dateString) {
      notification.warning({ message: 'Plz upload valid PF_ECR file' });
    }

    const date = getDate(dateString.slice(0, 8));
    if (!date) {
      notification.warning({ message: 'Plz upload valid PF_ECR file' });
    }
    // formData.append('paymentDate', date);
    epfIteartion(formData)
      .then((res) => {
        const transactionId1 = res.data.transactionId;
        setRequest({ transactionId1 });
        notification.success({
          message: 'PF-ECR Iteration Report successfully generated',
        });
      })
      .catch((err) => {
        // notification.error({ message: err.response.data.message });
        notification.success({
          message: 'PF-ECR Iteration Report successfully generated',
        });
      });
  };
  const getDate = (dateString) => {
    // Extract year, month, and day components
    const year = dateString.slice(0, 4);
    const month = dateString.slice(4, 6);
    const day = dateString.slice(6, 8);
    return new Date(`${year}-${month}-${day}`).toISOString().split('T')[0];
  };
  function handleDropdown2Change(value) {
    const [supplierIdValue, supplierName] = value.split(',');

    const supplierId = value.split(' ')[1];
    setSelectedSupplier(supplierIdValue);
    setSelectedDropdown2(supplierIdValue);
    // ExceptionReport();
    // if (supplierId !== users.Response1[0].id) {
    //   EmployeeData();
    // }
  }
  const EmployeeData = async () => {
    const querydata = {
      orgId: userConfig.orgId,
      supplierId: selectedDropdown2,
      type: 'PF',
    };

    const decryptPayload = await encrypt(JSON.stringify(querydata));
    const Response = axios
      .get(`report/transactions?payload=${decryptPayload}`)
      .then((response) => {
        console.log('Response is', response);
        setEmployee1(
          response.data.data.map((row) => ({
            transactionId: row.transactionId,
            // timestamp:row.timestamp,
            // timestamp: dayjs(row.timestamp, "YYYY-MM-DD"),
            timestamp: dayjs(row.timestamp).format('DD-MM-YYYY'),
            kronosFileName: row.kronosFileName,
            portalFileName: row.portalFileName,
            contractorName: row?.supplierName,
            acceptableDifference: row?.acceptableDifference,
            month: row?.month?.charAt(0)?.toUpperCase() + row?.month?.slice(1),
            issues: `Mismatched Data (${'10'})\nNo Match Data (${'0'})`,
            transactionStatus: 'PF ECR Pending',
            lastValidated: dayjs(row?.lastValidated).format(
              'HH:mm, DD-MM-YYYY'
            ),
            noOfIteration: row?.noOfIteration,
          }))
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    if (Request.transactionId1) {
      const report = axios
        .get(`/report/transactions/${Request.transactionId1}`)
        .then((res) => {
          setReportData(res.data);
          console.log('res220', res.data.data);

          setEmployee(
            res.data.data.report?.map((row) => ({
              empName: row['Emp Name'],
              empCode: row['Emp Code'],
              Uannumber: row['UAN Number'],
              Ebwr: row['Emp Basic Pay per Wage Reg'],
              Ebec: row['Emp Basic Pay per ECR Challan'],
              Basicwagesdiff: row['Basic Wages Diff'],
              Eepfwr: row['EE PF per Wage Reg'],
              Eepfc: row['EE PF per ECR Challan'],
              EmployeeDiff: row['Employee PF Diff'],
              Epswr: row['EPS per Wage Reg'],
              Epsec: row['EPS per ECR Challan'],
              Epsdiff: row['EPS Diff'],
              Erpfwr: row['ER PF per Wage Reg'],
              Erpfwrc: row['ER PF per ECR Challan'],
              EmployerDiff: row['Employer PF Diff'],
              dueDate: res?.data?.data?.dueDate,
              isCompliant: res?.data?.data?.isCompliant,
              supplierName: res?.data?.data?.supplierName,
            }))
          );
          const data = res.data;
          setExceptionRecords(data.data.exceptionCount > 0);
          setExcepEmployeeRecords(
            data.data.report
              ?.filter((record) => record.isExceptionRecord)
              .map((record) => ({
                empName1: record['Emp Name'],
                empCode1: record['Emp Code'],
                Uannumber1: record['UAN Number'],
                Ebwr1: record['Emp Basic Pay per Wage Reg'],
                Ebec1: record['Emp Basic Pay per ECR Challan'],
                Basicwagesdiff1: record['Basic Wages Diff'],
                Eepfwr1: record['EE PF per Wage Reg'],
                Eepfc1: record['EE PF per ECR Challan'],
                EmployeeDiff1: record['Employee PF Diff'],
                Epswr1: record['EPS per Wage Reg'],
                Epsec1: record['EPS per ECR Challan'],
                Epsdiff1: record['EPS Diff'],
                Erpfwr1: record['ER PF per Wage Reg'],
                Erpfwrc1: record['ER PF per ECR Challan'],
                EmployerDiff1: record['Employer PF Diff'],
                dueDate: res?.data?.data?.dueDate,
                isCompliant: res?.data?.data?.isCompliant,
              }))
          );

          setNoMatchRecords(
            data.data.report
              ?.filter((record) => record.isNoMatch)
              .map((record) => ({
                empName2: record['Emp Name'],
                empCode2: record['Emp Code'],
                Uannumber2: record['UAN Number'],
                dueDate: res?.data?.data?.dueDate,
                isCompliant: res?.data?.data?.isCompliant,
                // Ebwr1: record["Emp Basic Pay per Wage Reg"],
                // Ebec1: record["Emp Basic Pay per ECR Challan"],
                // Basicwagesdiff1: record["Basic Wages Diff"],
                // Eepfwr1: record["EE PF per Wage Reg"],
                // Eepfc1: record["EE PF per ECR Challan"],
                // EmployeeDiff1: record["Employee PF Diff"],
                // Epswr1: record["EPS per Wage Reg"],
                // Epsec1: record["EPS per ECR Challan"],
                // Epsdiff1: record["EPS Diff"],
                // Erpfwr1: record["ER PF per Wage Reg"],
                // Erpfwrc1: record["ER PF per ECR Challan"],
                // EmployerDiff1: record["Employer PF Diff"],
              }))
          );

          setTransactionId(res.data.transactionId);
          setModalVisible(true);
        })
        .catch((err) => {});
    }
  }, [Request.transactionId1]);

  React.useEffect(() => {
    EmployeeList();
    OrganizationInfo();
    CloudInfo();

    // ExceptionReport();
  }, []);

  const data = [
    { key: 1, month: 'January', value: 'Data 1' },
    { key: 2, month: 'February', value: 'Data 2' },
    { key: 3, month: 'March', value: 'Data 3' },
    { key: 4, month: 'April', value: 'Data 4' },
    { key: 5, month: 'May', value: 'Data 5' },
  ];

  const getMonthOptions = () => [
    { text: 'January', value: 'January' },
    { text: 'February', value: 'February' },
    { text: 'March', value: 'March' },
    { text: 'April', value: 'April' },
    { text: 'May', value: 'May' },
    { text: 'June', value: 'June' },
    { text: 'July', value: 'July' },
    { text: 'August', value: 'August' },
    { text: 'September', value: 'September' },
    { text: 'October', value: 'October' },
    { text: 'November', value: 'November' },
    { text: 'December', value: 'December' },
  ];

  const FileExplorer = ({ files, onFileSelect, onClose }) => {
    const handleFileChange = (file) => {
      setSelectedFile(file);
    };

    const handleUpload = () => {
      onFileSelect(selectedFile);
      onClose();
    };
    return (
      <Modal
        title='Cloud File Explorer'
        visible={true}
        onCancel={onClose}
        footer={[
          <Button key='back' onClick={onClose}>
            Cancel
          </Button>,
          <Button
            key='submit'
            type='primary'
            disabled={!selectedFile}
            onClick={handleUpload}>
            Upload
          </Button>,
        ]}>
        {/* Display files from prop using Ant Design List */}
        <List
          header={<div>Cloud Files</div>}
          bordered
          dataSource={files}
          renderItem={(file) => (
            <List.Item>
              <a href={file.link} target='_blank' rel='noopener noreferrer'>
                {file.name}
              </a>
            </List.Item>
          )}
        />
        {/* <Upload
          type="file"
          beforeUpload={() => false}
          onChange={(info) => handleFileChange(info.file)}
        >
          <Button icon={<UploadOutlined />}>Select File</Button>
        </Upload> */}
      </Modal>
    );
  };

  async function EmployeeList() {
    const Response = await axios.get('/supplier');
    const Response1 = Response.data.suppliers;
    setUsers({ Response1 });
  }

  const PfClick = () => {
    window.open('https://unifiedportal-emp.epfindia.gov.in', '_blank');
  };

  const PfChalanClick = () => {
    notification.info({
      message: 'Reminder Sent',
      description: 'PF challan reminder has been sent.',
      placement: 'topRight', // You can change the placement as needed
      duration: 3, // Auto-close after 3 seconds
    });
  };

  const handleModalCancel = () => {
    // Hide the modal
    setModalVisible(false);
  };

  const [contractorDetails, setContractorDetails] = useState({});

  const handleRowClick = (record) => {
    console.log('Row clicked:', record);
    // Perform actions like view or edit based on `record.transactionId`
    handleView(record.transactionId);
    setContractorDetails({
      contractorName: record.contractorName,
      acceptableDifference: record.acceptableDifference,
      month: record.month,
    });
  };

  const handleNotification = (e) => {
    console.log('inside eeee');
    e.stopPropagation();

    // Email({
    //   workmenName: feature.firstName + ' ' + feature.lastName,
    //   templateId: 'CREATE_APPROVER3',
    //   // VYNApplication: 'https://demo.wikiworks.in/login',
    //   VYNApplication: process.env.REACT_APP_URL,
    //   orgId: userConfig.orgId,
    //   role: feature?.role,
    //   level: feature.level,
    //   userId: feature.userid,
    //   password: feature.password,
    //   // locationId: deg.id,
    //   locationId: feature.locationId,
    // })
    //   .then((res) => {
    //     notification.success({ message: res.data.message });
    //   })
    //   .catch((err) => {
    //     notification.error({
    //       message: err.response.data.message,
    //     });
    //   });

    notification.info({
      message: 'Reminder Sent',
      description: 'PF challan reminder has been sent.',
      placement: 'topRight', // You can change the placement as needed
      duration: 3, // Auto-close after 3 seconds
    });
  };
  //Download Excel/Pdf
  function handleDropdown1Change(value) {
    setSelectedDropdown1(value);
  }

  // async function ExceptionReport() {
  //   const Response = axios.get(
  //     `/report/transactions?type=PF&orgId=${userConfig.orgId}&supplierId=${selectedDropdown2}&pageNo=1&pageSize=5`
  //   );
  // }

  const downloadFile = async (evt) => {
    evt.preventDefault();
    const url = `/report/download/${selectedDropdown1}`;
    const data = {
      reportType: 'all',
      transactionId: Request.transactionId1 || transactionId,
      selectedDropdown2,
      supplierId: selectedSupplier,
    };

    let responseType, fileExtension, contentType;
    if (selectedDropdown1 === 'PDF') {
      responseType = 'arraybuffer';
      fileExtension = 'pdf';
      contentType = 'application/pdf';
    } else if (selectedDropdown1 === 'Excel') {
      responseType = 'blob';
      fileExtension = 'xlsx';
      contentType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    }
    axios
      .post(url, data, { responseType })
      .then((response) => {
        const file = new Blob([response.data], { type: contentType });
        const fileUrl = window.URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', `file.${fileExtension}`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const downloadFile1 = async (evt) => {
    evt.preventDefault();
    const url = `/report/download/${selectedDropdown1}`;
    const data = {
      reportType: 'exception',
      // transactionId: Request.transactionId1,
      transactionId: Request.transactionId1 || transactionId,

      selectedDropdown2,
      supplierId: selectedSupplier,
    };

    let responseType, fileExtension, contentType;
    if (selectedDropdown1 === 'PDF') {
      responseType = 'arraybuffer';
      fileExtension = 'pdf';
      contentType = 'application/pdf';
    } else if (selectedDropdown1 === 'Excel') {
      responseType = 'blob';
      fileExtension = 'xlsx';
      contentType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    }
    axios
      .post(url, data, { responseType })
      .then((response) => {
        const file = new Blob([response.data], { type: contentType });
        const fileUrl = window.URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', `file.${fileExtension}`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error(error);
      });
  };
  function handleView(id) {
    setTransactionId(id);
    setModalVisible(true);
    setVisible(true);
    setLoading(true);
    axios
      .get(`/report/transactions/${id}`)
      .then((response) => {
        setReportData(response.data);
        console.log('545', response.data.data.supplierName);

        setEmployee(
          response.data.data.report?.map((row) => ({
            empName: row['Emp Name'],
            empCode: row['Emp Code'],
            Uannumber: row['UAN Number'],
            Ebwr: row['Emp Basic Pay per Wage Reg'],
            Ebec: row['Emp Basic Pay per ECR Challan'],
            Basicwagesdiff: row['Basic Wages Diff'],
            Eepfwr: row['EE PF per Wage Reg'],
            Eepfc: row['EE PF per ECR Challan'],
            EmployeeDiff: row['Employee PF Diff'],
            Epswr: row['EPS per Wage Reg'],
            Epsec: row['EPS per ECR Challan'],
            Epsdiff: row['EPS Diff'],
            Erpfwr: row['ER PF per Wage Reg'],
            Erpfwrc: row['ER PF per ECR Challan'],
            EmployerDiff: row['Employer PF Diff'],
            supplierName: response?.data?.data?.supplierName,
          }))
        );
        const data = response.data;
        setExceptionRecords(data.data.exceptionCount > 0);

        setExcepEmployeeRecords(
          data.data.report
            ?.filter((record) => record.isExceptionRecord)
            .map((record) => ({
              empName1: record['Emp Name'],
              empCode1: record['Emp Code'],
              Uannumber1: record['UAN Number'],
              Ebwr1: record['Emp Basic Pay per Wage Reg'],
              Ebec1: record['Emp Basic Pay per ECR Challan'],
              Basicwagesdiff1: record['Basic Wages Diff'],
              Eepfwr1: record['EE PF per Wage Reg'],
              Eepfc1: record['EE PF per ECR Challan'],
              EmployeeDiff1: record['Employee PF Diff'],
              Epswr1: record['EPS per Wage Reg'],
              Epsec1: record['EPS per ECR Challan'],
              Epsdiff1: record['EPS Diff'],
              Erpfwr1: record['ER PF per Wage Reg'],
              Erpfwrc1: record['ER PF per ECR Challan'],
              EmployerDiff1: record['Employer PF Diff'],
            }))
        );
        setNoMatchRecords(
          data.data.report
            ?.filter((record) => record.isNoMatch)
            .map((record) => ({
              empName2: record['Emp Name'],
              empCode2: record['Emp Code'],
              Uannumber2: record['UAN Number'],
              // Ebwr1: record["Emp Basic Pay per Wage Reg"],
              // Ebec1: record["Emp Basic Pay per ECR Challan"],
              // Basicwagesdiff1: record["Basic Wages Diff"],
              // Eepfwr1: record["EE PF per Wage Reg"],
              // Eepfc1: record["EE PF per ECR Challan"],
              // EmployeeDiff1: record["Employee PF Diff"],
              // Epswr1: record["EPS per Wage Reg"],
              // Epsec1: record["EPS per ECR Challan"],
              // Epsdiff1: record["EPS Diff"],
              // Erpfwr1: record["ER PF per Wage Reg"],
              // Erpfwrc1: record["ER PF per ECR Challan"],
              // EmployerDiff1: record["Employer PF Diff"],
            }))
        );

        // setData(response.data);
        setLoading(false);
        // setLoading(false);
        // EmployeeData();
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }
  const handleDownload = (download) => {
    // download.preventDefault();
    axios
      .post(
        `/report/download/PDF`,
        {
          reportType: 'exception',
          transactionId: download,
          supplierId: selectedSupplier,
        },
        { responseType: 'arraybuffer' }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.pdf');
        document.body.appendChild(link);
        link.click();
      });
  };

  // const columns = [
  //   {
  //     title: 'Workmen Name',
  //     dataIndex: 'empName',
  //     key: 'empName',
  //   },
  //   {
  //     title: 'Workmen Code',
  //     dataIndex: 'empCode',
  //     key: 'empCode',
  //   },
  //   {
  //     title: 'UAN Number',
  //     dataIndex: 'Uannumber',
  //     key: 'Uannumber',
  //   },
  //   {
  //     title: 'Emp Basic Pay Per Wage Reg',
  //     dataIndex: 'Ebwr',
  //     key: 'Ebwr',
  //   },
  //   {
  //     title: 'Emp Basic Pay Per ECR Challan',
  //     dataIndex: 'Ebec',
  //     key: 'Ebec',
  //   },
  //   {
  //     title: 'Basic Wages Diff',
  //     dataIndex: 'Basicwagesdiff',
  //     key: 'Basicwagesdiff',
  //   },
  //   {
  //     title: 'EE PF per Wage Reg',
  //     dataIndex: 'Eepfwr',
  //     key: 'Eepfwr',
  //   },
  //   {
  //     title: 'EE PF per ECR Challan',
  //     dataIndex: 'Eepfc',
  //     key: 'Eepfc',
  //   },
  //   {
  //     title: 'Employee PF Diff',
  //     dataIndex: 'EmployeeDiff',
  //     key: 'EmployeeDiff',
  //   },
  //   {
  //     title: 'EPS per Wage Reg',
  //     dataIndex: 'Epswr',
  //     key: 'Epswr',
  //   },
  //   {
  //     title: 'EPS per ECR Challan',
  //     dataIndex: 'Epsec',
  //     key: 'Epsec',
  //   },
  //   {
  //     title: 'EPS Diff',
  //     dataIndex: 'Epsdiff',
  //     key: 'Epsdiff',
  //   },
  //   {
  //     title: 'ER PF per Wage Reg',
  //     dataIndex: 'Erpfwr',
  //     key: 'Erpfwr',
  //   },
  //   {
  //     title: 'ER PF per ECR Challan',
  //     dataIndex: 'Erpfwrc',
  //     key: 'Erpfwrc',
  //   },
  //   {
  //     title: 'Employer PF Diff',
  //     dataIndex: 'EmployerDiff',
  //     key: 'EmployerDiff',
  //   },
  //   {
  //     title: 'Transaction Date',
  //     dataIndex: 'dueDate',
  //     key: 'dueDate',
  //     render: (item, record) => {
  //       return <span>{item ? dayjs(item).format('YYYY-DD-MM') : ''}</span>;
  //     },
  //   },
  //   {
  //     title: 'Remarks',
  //     dataIndex: 'isCompliant',
  //     key: 'isCompliant',
  //     render: (item, record) => {
  //       return <span>{item ? 'compliant' : 'Not compliant'} </span>;
  //     },
  //   },
  // ];
  const columns = [
    {
      title: 'Workmen Name',
      dataIndex: 'empName',
      key: 'empName',
    },
    {
      title: 'Workmen Code',
      dataIndex: 'empCode',
      key: 'empCode',
    },
    {
      title: 'UAN Number',
      dataIndex: 'Uannumber',
      key: 'Uannumber',
    },
    {
      title: 'Emp Basic Pay Per Wage Reg',
      dataIndex: 'Ebwr',
      key: 'Ebwr',
    },
    {
      title: 'Emp Basic Pay Per ECR Challan',
      dataIndex: 'Ebec',
      key: 'Ebec',
    },
    {
      title: 'Basic Wages Diff',
      dataIndex: 'Basicwagesdiff',
      key: 'Basicwagesdiff',
      render: (value) => {
        return (
          <span style={{ color: value > 0 ? 'red' : 'inherit' }}>{value}</span>
        );
      },
    },
    {
      title: 'EE PF per Wage Reg',
      dataIndex: 'Eepfwr',
      key: 'Eepfwr',
    },
    {
      title: 'EE PF per ECR Challan',
      dataIndex: 'Eepfc',
      key: 'Eepfc',
    },
    {
      title: 'Employee PF Diff',
      dataIndex: 'EmployeeDiff',
      key: 'EmployeeDiff',
      render: (value) => {
        return (
          <span style={{ color: value > 0 ? 'red' : 'inherit' }}>{value}</span>
        );
      },
    },
    {
      title: 'EPS per Wage Reg',
      dataIndex: 'Epswr',
      key: 'Epswr',
    },
    {
      title: 'EPS per ECR Challan',
      dataIndex: 'Epsec',
      key: 'Epsec',
    },
    {
      title: 'EPS Diff',
      dataIndex: 'Epsdiff',
      key: 'Epsdiff',
      render: (value) => {
        return (
          <span style={{ color: value > 0 ? 'red' : 'inherit' }}>{value}</span>
        );
      },
    },
    {
      title: 'ER PF per Wage Reg',
      dataIndex: 'Erpfwr',
      key: 'Erpfwr',
    },
    {
      title: 'ER PF per ECR Challan',
      dataIndex: 'Erpfwrc',
      key: 'Erpfwrc',
    },
    {
      title: 'Employer PF Diff',
      dataIndex: 'EmployerDiff',
      key: 'EmployerDiff',
    },
    {
      title: 'Transaction Date',
      dataIndex: 'dueDate',
      key: 'dueDate',
      render: (item) => {
        const transactionDate = dayjs(item);
        const currentDate = dayjs();
        const daysDifference = currentDate.diff(transactionDate, 'day');

        return (
          <span style={{ color: daysDifference > 16 ? 'red' : 'inherit' }}>
            {item ? transactionDate.format('DD-MM-YYYY') : ''}
          </span>
        );
      },
    },

    {
      title: 'Remarks',
      dataIndex: 'isCompliant',
      key: 'isCompliant',
      render: (item, record) => {
        const { Basicwagesdiff, EmployeeDiff, EmployerDiff, dueDate } = record;
        const transactionDate = dayjs(dueDate);

        let remarks = '';
        let color = '';

        if (
          Basicwagesdiff === 0 &&
          EmployeeDiff === 0 &&
          EmployerDiff === 0 &&
          transactionDate.date() > 15
        ) {
          remarks = 'Non-Compliant (Crossing the Due Date)';
          color = 'red';
        } else if (
          Basicwagesdiff === 0 &&
          EmployeeDiff === 0 &&
          EmployerDiff === 0
        ) {
          remarks = 'Fully Compliant';
          // color = 'green';
        } else if (
          Basicwagesdiff < 5 &&
          EmployeeDiff < 5 &&
          EmployerDiff < 5 &&
          transactionDate.date() <= 15
        ) {
          remarks = 'Compliant';
          // color = 'green';
        } else {
          remarks = 'Non-Compliant (Crossing the Tolerance Limit)';
          // color = 'red';
        }

        return <span style={{ color }}>{remarks}</span>;
      },
    },
  ];

  const Exceptionscolumns = [
    {
      title: 'Workmen Name',
      dataIndex: 'empName1',
      key: 'empName1',
    },
    {
      title: 'Workmen Code',
      dataIndex: 'empCode1',
      key: 'empCode1',
    },
    {
      title: 'UAN Number',
      dataIndex: 'Uannumber1',
      key: 'Uannumber1',
    },
    {
      title: 'Emp Basic Pay Per Wage Reg',
      dataIndex: 'Ebwr1',
      key: 'Ebwr1',
    },
    {
      title: 'Emp Basic Pay Per ECR Challan',
      dataIndex: 'Ebec1',
      key: 'Ebec1',
    },
    {
      title: 'Basic Wages Diff',
      dataIndex: 'Basicwagesdiff1',
      key: 'Basicwagesdiff1',
    },
    {
      title: 'EE PF per Wage Reg',
      dataIndex: 'Eepfwr1',
      key: 'Eepfwr1',
    },
    {
      title: 'EE PF per ECR Challan',
      dataIndex: 'Eepfc1',
      key: 'Eepfc1',
    },
    {
      title: 'Employee PF Diff',
      dataIndex: 'EmployeeDiff1',
      key: 'EmployeeDiff1',
    },
    {
      title: 'EPS per Wage Reg',
      dataIndex: 'Epswr1',
      key: 'Epswr1',
    },
    {
      title: 'EPS per ECR Challan',
      dataIndex: 'Epsec1',
      key: 'Epsec1',
    },
    {
      title: 'EPS Diff',
      dataIndex: 'Epsdiff1',
      key: 'Epsdiff1',
    },
    {
      title: 'ER PF per Wage Reg',
      dataIndex: 'Erpfwr1',
      key: 'Erpfwr1',
    },
    {
      title: 'ER PF per ECR Challan',
      dataIndex: 'Erpfwrc1',
      key: 'Erpfwrc1',
    },
    {
      title: 'Employer PF Diff',
      dataIndex: 'EmployerDiff1',
      key: 'EmployerDiff1',
    },
    {
      title: 'Transaction Date',
      dataIndex: 'dueDate',
      key: 'dueDate',
      render: (item, record) => {
        return <span>{item ? dayjs(item).format('YYYY-DD-MM') : ''}</span>;
      },
    },
    {
      title: 'Compliant',
      dataIndex: 'isCompliant',
      key: 'isCompliant',
      render: (item, record) => {
        return <span>{item ? 'compliant' : 'Not compliant'} </span>;
      },
    },
  ];
  const nomatchRecord = [
    {
      title: 'Workmen Name',
      dataIndex: 'empName2',
      key: 'empName2',
    },
    {
      title: 'Workmen Code',
      dataIndex: 'empCode2',
      key: 'empCode2',
    },
    {
      title: 'UAN Number',
      dataIndex: 'Uannumber2',
      key: 'Uannumber2',
    },
    {
      title: 'Emp Basic Pay Per Wage Reg',
      dataIndex: 'Ebwr1',
      key: 'Ebwr1',
    },
    {
      title: 'Emp Basic Pay Per ECR Challan',
      dataIndex: 'Ebec1',
      key: 'Ebec1',
    },
    {
      title: 'Basic Wages Diff',
      dataIndex: 'Basicwagesdiff1',
      key: 'Basicwagesdiff1',
    },
    {
      title: 'EE PF per Wage Reg',
      dataIndex: 'Eepfwr1',
      key: 'Eepfwr1',
    },
    {
      title: 'EE PF per ECR Challan',
      dataIndex: 'Eepfc1',
      key: 'Eepfc1',
    },
    {
      title: 'Employee PF Diff',
      dataIndex: 'EmployeeDiff1',
      key: 'EmployeeDiff1',
    },
    {
      title: 'EPS per Wage Reg',
      dataIndex: 'Epswr1',
      key: 'Epswr1',
    },
    {
      title: 'EPS per ECR Challan',
      dataIndex: 'Epsec1',
      key: 'Epsec1',
    },
    {
      title: 'EPS Diff',
      dataIndex: 'Epsdiff1',
      key: 'Epsdiff1',
    },
    {
      title: 'ER PF per Wage Reg',
      dataIndex: 'Erpfwr1',
      key: 'Erpfwr1',
    },
    {
      title: 'ER PF per ECR Challan',
      dataIndex: 'Erpfwrc1',
      key: 'Erpfwrc1',
    },
    {
      title: 'Employer PF Diff',
      dataIndex: 'EmployerDiff1',
      key: 'EmployerDiff1',
    },
    {
      title: 'Transaction Date',
      dataIndex: 'dueDate',
      key: 'dueDate',
      render: (item, record) => {
        return <span>{item ? dayjs(item).format('YYYY-DD-MM') : ''}</span>;
      },
    },
    {
      title: 'Compliant',
      dataIndex: 'isCompliant',
      key: 'isCompliant',
      render: (item, record) => {
        return <span>{item ? 'compliant' : 'Not compliant'} </span>;
      },
    },
  ];
  // const column = [

  //   {
  //     title: 'Transaction ID',
  //     dataIndex: 'transactionId',
  //     key: 'transactionId',
  //     editable: true,
  //   },
  //   {
  //     title: 'Date',
  //     dataIndex: 'timestamp',
  //     key: 'timestamp',
  //   },
  //   {
  //     title: 'Wage Register',
  //     dataIndex: 'kronosFileName',
  //     key: 'kronosFileName',
  //   },
  //   {
  //     title: 'Portal File',
  //     dataIndex: 'portalFileName',
  //     key: 'portalFileName',
  //   },
  //   {
  //     title: 'Actions',
  //     dataIndex: 'transactionId',
  //     render: (_, record) => (
  //       <Space>
  //         <Typography onClick={() => handleDownload(record.transactionId)}>
  //           <DownloadOutlined />
  //         </Typography>
  //         <Typography onClick={() => handleView(record.transactionId)}>
  //           <EyeFilled />
  //         </Typography>
  //       </Space>
  //     ),
  //   },
  // ];

  useEffect(() => {
    if (users.Response1) {
      const filters = users.Response1.map((option) => ({
        text: option.name, // Visible in the filter dropdown
        value: option.name, // Used for filtering logic
      }));
      setContractorFilters(filters); // Set the filters dynamically
    }
  }, [users.Response1]);

  const [selectedContractors, setSelectedContractors] = useState([]); // State to track selected rows
  const [pfChalanEnable, setPFchalanEnable] = useState(true);
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedContractors(selectedRows.map((row) => row.contractorName)); // Save selected contractor names
      setPFchalanEnable(false);
    },
    getCheckboxProps: (record) => ({
      name: record.contractorName, // Customize checkbox name if needed
    }),
  };

  const column = [
    {
      title: 'Contractor Name',
      dataIndex: 'contractorName',
      key: 'contractorName',
      filters: contractorFilters, // Dynamically populated filters
      onFilter: (value, record) => record.contractorName === value, // Filter logic
      filterSearch: true, // Enables search inside the filter dropdown
    },
    {
      title: 'Wage Register',
      dataIndex: 'kronosFileName',
      key: 'kronosFileName',
    },
    {
      title: 'Month',
      dataIndex: 'month',
      key: 'month',
      filters: getMonthOptions(), // Full list of months for filtering
      onFilter: (value, record) => record.month === value, // Filter logic
      filterSearch: true, // Enables search inside the filter dropdown
    },
    {
      title: 'Acceptable Difference(₹)',
      dataIndex: 'acceptableDifference',
      key: 'acceptableDifference',
    },
    {
      title: 'Issues',
      dataIndex: 'issues',
      key: 'issues',
    },
    {
      title: 'Transaction Status',
      dataIndex: 'transactionStatus',
      key: 'transactionStatus',
      filters: [
        {
          text: 'PF-ECR Uploaded',
          value: 'pfEcrUploaded',
        },
        {
          text: 'PF-ECR Pending',
          value: 'pfEcrPending',
        },
        {
          text: 'Closed',
          value: 'closed',
        },
      ],
      render: (text) => {
        let statusColor = 'black'; // Default color

        // Check the value of `text` and set the color accordingly
        if (text === 'pfEcrUploaded') {
          statusColor = '#28a745'; // Green for "Uploaded"
        } else if (text === 'pfEcrPending') {
          statusColor = '#ffc107'; // Yellow for "Pending"
        } else if (text === 'closed') {
          statusColor = '#dc3545'; // Red for "Closed"
        }

        return <span style={{ color: 'red', fontWeight: 'bold' }}>{text}</span>;
      },
    },
    {
      title: 'Last Validated',
      dataIndex: 'lastValidated',
      key: 'lastValidated',
    },
    {
      title: 'No. of Iteration',
      dataIndex: 'noOfIteration',
      key: 'noOfIteration',
    },
    {
      title: 'Action',
      dataIndex: 'transactionId',
      render: (_, record) => (
        <Space>
          <Typography
            onClick={() => handleDownload(record.transactionId)}
            style={{
              color: '#1677FF',
              cursor: 'pointer',
              fontWeight: '600',
              textDecoration: 'underline',
              backgroundColor: 'white',
            }}>
            <DownloadOutlined />
          </Typography>
          <Typography onClick={handleNotification}>
            <AlertTwoTone />
          </Typography>
        </Space>
      ),
    },
  ];
  const [principalEmployername, setprincipalEmployername] = useState(null);
  const [files, setFiles] = useState([]);

  const OrganizationInfo = async () => {
    getContractorOnboard().then((res) => {
      const name = res.data.org.name;
      setprincipalEmployername(res.data.org.name);
    });
  };

  const CloudInfo = async () => {
    getCloudInfo().then((res) => {
      const data = res.data;
      setFiles(data);
    });
  };

  const openGoogleCloudStorageBucket = async () => {
    // Wait for the file upload to finish asynchronously
    await new Promise((resolve) => setTimeout(resolve, 1000));

    // Replace 'your_bucket_name' with the actual name of your Google Cloud Storage bucket
    const bucketName = process.env.BUCKET_NAME;

    // Open the bucket in the Google Cloud Storage Browser
    window.open(
      `https://console.cloud.google.com/storage/browser/${bucketName}`,
      '_blank'
    );
    // window.location.href = `https://console.cloud.google.com/storage/browser/${bucketName}`;
  };

  const openFileExplorer = () => {
    setFileExplorerVisible(true);
  };

  const closeFileExplorer = () => {
    setFileExplorerVisible(false);
  };

  const handleFileSelect = (file) => {
    // Handle the selected file (e.g., update state, perform actions)
  };

  const sampleFiles = [
    { id: 1, name: 'File 1.txt' },
    { id: 2, name: 'File 2.jpg' },
    // Add more files as needed
  ];

  //-------------Month
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  console.log('1289', reportData?.data);

  return (
    <>
      <Form layout='vertical' form={form} onFinish={onFinish}>
        <Row justify='space-between' align='middle'>
          <Col>
            <Title
              level={4}
              style={{ color: '#000000', Font: 'inter', fontWeight: '600' }}>
              PF Compliance Reconciliation
            </Title>
          </Col>

          <Col>
            <span
              onClick={PfClick}
              style={{
                color: '#1677FF',
                cursor: 'pointer',
                fontWeight: '600',
                textDecoration: 'underline',
              }}>
              Go to PF Portal
            </span>
          </Col>
        </Row>
        <Title level={4}>Validation</Title>

        <Row>
          <Col span={4}>
            <Form.Item
              label='Contractor Name'
              name='supplierId'
              rules={[
                {
                  required: true,
                  message: 'Select Contractor',
                },
              ]}>
              <Select
                style={{ width: '200px' }}
                onSelect={(value, option) =>
                  handleDropdown2Change(value, option.label)
                }>
                {users.Response1?.map((option) => (
                  <Option
                    key={option.id}
                    label={option.name}
                    value={`${option._id},${option.name}`}>
                    {option.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item
              label='Acceptable difference(₹)'
              name='acceptableDifference'
              rules={[
                {
                  required: true,
                  message: 'Enter Acceptable difference(₹)',
                },
              ]}>
              <Input style={{ width: '200px' }} placeholder='Eg :4'></Input>
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item
              label='Month'
              name='month'
              rules={[
                {
                  required: true,
                  message: 'Select a month',
                },
              ]}>
              <Select style={{ width: '200px' }} placeholder='Select'>
                {months.map((month, index) => (
                  <Option key={index} value={month.toLowerCase()}>
                    {month}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item
              label='Upload Wage Register'
              name='kronosFile'
              rules={[{ required: true, message: 'Upload Wage Register' }]}>
              <Upload
                type='file'
                accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                beforeUpload={() => false}
                // fileList={kronosFile ? [kronosFile] : []}
                onChange={(info) => {
                  const { status } = info.file;
                  if (status === 'done') {
                    onKronosFileUpload(info.file);
                  }
                }}>
                <Button
                  icon={<UploadOutlined />}
                  style={{
                    color: '#1677FF',
                    cursor: 'pointer',
                    fontWeight: '600',
                    textDecoration: 'underline',
                  }}></Button>
              </Upload>
            </Form.Item>
            {/* <Form.Item
              label='Cloud Upload'
              name='kronosFile'
              rules={[{ required: true, message: 'Upload Document' }]}>
              <Button onClick={openFileExplorer} icon={<UploadOutlined />}>
                Upload Wage Register
              </Button>
            </Form.Item> */}
          </Col>
          <Col span={4}>
            <Form.Item
              label='Upload PF-ECR file'
              name='portalFile'
              rules={[{ required: true, message: 'Upload PF-ECR file' }]}>
              <Upload
                type='file'
                accept='text/plain'
                beforeUpload={() => false}
                onChange={(info) => {
                  const { status } = info.file;
                  if (status === 'done') {
                    onPortalFileUpload(info.file);
                  }
                }}>
                <Button
                  icon={<UploadOutlined />}
                  style={{
                    color: '#1677FF',
                    cursor: 'pointer',
                    fontWeight: '600',
                    textDecoration: 'underline',
                  }}></Button>
              </Upload>
            </Form.Item>
            {/* <Form.Item
              label=' Cloud Upload PF-ECR file'
              name='portalFile'
              rules={[{ required: true, message: 'Upload Document' }]}>
              <Button onClick={openFileExplorer} icon={<UploadOutlined />}>
                Upload PF-ECR file
              </Button>
            </Form.Item> */}
          </Col>
          <Col span={4}>
            <Button
              // onClick={handleCompareButtonClick}
              type='primary'
              style={{ marginRight: '100px', marginTop: '25px' }}
              htmlType='submit'>
              Validate
            </Button>
          </Col>
        </Row>
        {/* <Divider></Divider> */}

        <Row justify='space-between' align='middle'>
          <Col>
            <Title level={4}>History</Title>
          </Col>

          {/* <Col>
            <span
              onClick={PfChalanClick}
              disabled={pfChalanEnable}
              style={{
                color: '#6AA8FF',
                cursor: 'pointer',
                textDecoration: 'underline',
              }}>
              PF Chalan Reminder
            </span>
          </Col> */}
          <Col>
            <span
              onClick={!pfChalanEnable ? PfChalanClick : undefined} // Disable click if `pfChalanEnable` is false
              style={{
                color: pfChalanEnable ? '#6AA8FF' : '#1677FF', // Gray out if disabled
                cursor: pfChalanEnable ? 'not-allowed' : 'pointer', // Show a not-allowed cursor when disabled
                textDecoration: 'underline',
                fontWeight: '600',
              }}>
              PF Chalan Reminder
            </span>
          </Col>
        </Row>

        {fileExplorerVisible && (
          <FileExplorer
            files={files}
            onFileSelect={handleFileSelect}
            onClose={closeFileExplorer}
          />
        )}

        <Modal
          visible={modalVisible}
          onCancel={handleModalCancel}
          centered
          width='80%'
          footer={null}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center', // Horizontally center the steps
              // alignItems: 'center', // Vertically center the steps
              // height: '100vh', // Optional: Center vertically within the viewport
            }}>
            <Steps
              current={currentStep}
              style={{
                width: '100%', // Make it responsive
                maxWidth: '600px', // Optional: Limit the width for better aesthetics
              }}>
              <Step
                title={`All Employees (${reportData?.data?.allCount})`}
                onClick={() => setCurrentStep(0)}
              />
              <Step
                title={`Mis Match (${reportData?.data?.exceptionCount})`}
                onClick={() => setCurrentStep(1)}
              />
              {/* Uncomment if needed */}
              <Step
                title={`No Match (${reportData?.data?.noMatchCount})`}
                onClick={() => setCurrentStep(2)}
              />
            </Steps>
          </div>
          {currentStep === 0 && (
            <div>
              <div>
                {/* Inputs Row */}
                <Row
                  gutter={[16, 16]}
                  justify='center'
                  align='middle'
                  style={{
                    textAlign: 'center',
                    padding: '30px',
                    margin: '0 auto',

                    maxWidth: '900px',
                  }}>
                  {/* Contractor Name */}
                  <Col xs={24} sm={12} md={8}>
                    {' '}
                    {/* Responsive layout */}
                    <Input
                      value={`Contractor Name: ${
                        contractorDetails?.contractorName ||
                        reportData?.data?.supplierName
                      }`} // Use template literals correctly
                      disabled
                      style={{
                        color: 'black',
                        textAlign: 'center',
                        backgroundColor: '#E3F8FF',
                        border: 'none',
                        cursor: 'default',
                        fontWeight: 'bold',
                        borderRadius: '4px',
                        padding: '8px',
                      }}
                    />
                  </Col>

                  <Col xs={24} sm={12} md={8}>
                    {' '}
                    <Input
                      value={`Acceptable difference(₹): ${
                        contractorDetails.acceptableDifference ||
                        reportData?.data?.acceptableDifference
                      }`} // Use template literals correctly
                      disabled
                      style={{
                        color: 'black',
                        textAlign: 'center',
                        backgroundColor: '#E3F8FF',
                        border: 'none',
                        cursor: 'default',
                        fontWeight: 'bold',
                        borderRadius: '4px',
                        padding: '8px',
                      }}
                    />
                  </Col>

                  {/* Month */}
                  <Col xs={24} sm={12} md={8}>
                    {' '}
                    {/* Responsive layout */}
                    <Input
                      value={`Month: ${
                        contractorDetails.month || reportData?.data?.month
                      }`} // Use template literals correctly
                      disabled
                      style={{
                        color: 'black', // Value color
                        textAlign: 'center',
                        backgroundColor: '#E3F8FF',
                        border: 'none',
                        cursor: 'default',
                        fontWeight: 'bold', // Highlight the text
                        borderRadius: '4px', // Rounded input corners
                        padding: '8px', // Add padding for input
                      }}
                    />
                  </Col>
                </Row>

                <Row
                  gutter={[8, 8]} // Reduced gutter for less padding
                  align='middle' // Vertically center the content
                  justify='center' // Horizontally center the column content
                  style={{ height: '100%' }} // Ensure row takes full height if needed
                >
                  {/* Last Validated - Full Center Alignment */}
                  <Col span={8}></Col>
                  <Col
                    span={8}
                    style={{
                      textAlign: 'center', // Align the content inside the column
                    }}>
                    <Input
                      value='Last Validated: 05:03 pm, 06-12-2024'
                      disabled
                      style={{
                        fontWeight: 'bold',
                        color: 'black',
                        backgroundColor: 'transparent',
                        border: 'none',
                        cursor: 'default',
                        textAlign: 'center', // Ensure the text inside the input is centered
                      }}
                    />
                  </Col>

                  {/* Acceptable Difference and Download Controls */}
                  <Col span={8} style={{ textAlign: 'right' }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        gap: '10px',
                      }}>
                      <Select
                        getPopupContainer={getPopupContainer}
                        style={{
                          fontWeight: 'bolder',
                          color: 'black',
                          width: '80px',
                        }}
                        onChange={handleDropdown1Change}
                        placeholder='PDF'>
                        <Option value='PDF'>PDF</Option>
                        <Option value='Excel'>Excel</Option>
                      </Select>
                      <Button
                        type='primary'
                        style={{
                          color: '#1677FF',
                          cursor: 'pointer',
                          fontWeight: '600',
                          textDecoration: 'underline',
                          backgroundColor: 'white',
                        }}
                        onClick={(evt) => downloadFile(evt)}>
                        <DownloadOutlined /> {/* Apply bold style */}
                      </Button>
                    </div>
                  </Col>
                </Row>

                <Table
                  columns={columns}
                  dataSource={Employee}
                  pagination={{ pageSize: 5 }}
                  scroll={{ x: 1500 }}
                  style={{ marginTop: '8px' }} // Adjust table margin
                />
                {/* Download Section */}
                {/* <Row
                  gutter={[8, 8]}
                  style={{
                    justifyContent: 'end',
                    marginTop: '8px',
                    padding: '10px',
                  }}>
                  <Col span={12} offset={12} style={{ textAlign: 'right' }}>
                    <Select
                      getPopupContainer={getPopupContainer}
                      style={{
                        fontWeight: 'bolder',
                        color: 'black',
                        width: '120px',
                      }}
                      onChange={handleDropdown1Change}
                      placeholder='PDF'>
                      <Option value='PDF'>PDF</Option>
                      <Option value='Excel'>Excel</Option>
                    </Select>
                    <Button
                      style={{ float: 'right', marginLeft: '10px' }}
                      type='primary'
                      onClick={(evt) => downloadFile(evt)}>
                      Download
                    </Button>
                  </Col>
                </Row> */}
              </div>
            </div>
          )}

          {currentStep === 1 && exceptionRecords && (
            <div>
              <div>
                {/* Inputs Row */}
                <Row
                  gutter={[16, 16]}
                  justify='center'
                  align='middle'
                  style={{
                    textAlign: 'center',
                    padding: '30px',
                    margin: '0 auto',

                    maxWidth: '900px',
                  }}>
                  {/* Contractor Name */}
                  <Col xs={24} sm={12} md={8}>
                    {' '}
                    {/* Responsive layout */}
                    <Input
                      value={`Contractor Name: ${
                        contractorDetails?.contractorName ||
                        reportData?.data?.supplierName
                      }`} // Use template literals correctly
                      disabled
                      style={{
                        color: 'black',
                        textAlign: 'center',
                        backgroundColor: '#E3F8FF',
                        border: 'none',
                        cursor: 'default',
                        fontWeight: 'bold',
                        borderRadius: '4px',
                        padding: '8px',
                      }}
                    />
                  </Col>

                  <Col xs={24} sm={12} md={8}>
                    {' '}
                    <Input
                      value={`Acceptable difference(₹): ${
                        contractorDetails.acceptableDifference ||
                        reportData?.data?.acceptableDifference
                      }`} // Use template literals correctly
                      disabled
                      style={{
                        color: 'black',
                        textAlign: 'center',
                        backgroundColor: '#E3F8FF',
                        border: 'none',
                        cursor: 'default',
                        fontWeight: 'bold',
                        borderRadius: '4px',
                        padding: '8px',
                      }}
                    />
                  </Col>

                  {/* Month */}
                  <Col xs={24} sm={12} md={8}>
                    {' '}
                    {/* Responsive layout */}
                    <Input
                      value={`Month: ${
                        contractorDetails.month || reportData?.data?.month
                      }`} // Use template literals correctly
                      disabled
                      style={{
                        color: 'black', // Value color
                        textAlign: 'center',
                        backgroundColor: '#E3F8FF',
                        border: 'none',
                        cursor: 'default',
                        fontWeight: 'bold', // Highlight the text
                        borderRadius: '4px', // Rounded input corners
                        padding: '8px', // Add padding for input
                      }}
                    />
                  </Col>
                </Row>
                {/* <Divider style={{ margin: '10px 0' }} />{' '} */}
                {/* Reduced divider margins */}
              </div>
              {/* <Divider style={{ margin: '10px 0' }} />{' '} */}
              {/* Reduced divider margins */}
              <Row gutter={[8, 8]} align='middle'>
                {/* First Column - Start Alignment */}
                <Col
                  span={12}
                  style={{
                    textAlign: 'start', // Align content to the start
                  }}>
                  <Form.Item
                    name='portalFile'
                    rules={[
                      {
                        required: true,
                        message: 'Please upload the PF-ECR file',
                      },
                    ]}>
                    <Row
                      style={{
                        display: 'flex',
                        alignItems: 'center', // Align items vertically centered
                        gap: '10px', // Add some space between buttons
                      }}>
                      <Upload
                        accept='text/plain'
                        beforeUpload={(file) => {
                          setFile(file);
                          onPortalFileUpload(file);
                          return false;
                        }}
                        maxCount={1}>
                        <Tooltip title='Upload PF-ECR File'>
                          <Button icon={<UploadOutlined />} />
                        </Tooltip>{' '}
                      </Upload>
                      <Button
                        type='primary'
                        onClick={updateAndValidate}
                        disabled={!file}>
                        Validate
                      </Button>
                    </Row>
                  </Form.Item>
                </Col>

                {/* Second Column - End Alignment */}
                <Col
                  span={12}
                  style={{
                    textAlign: 'end', // Align content to the end
                  }}>
                  <Input
                    value='No.of Iteration:1'
                    disabled
                    style={{
                      fontWeight: 'bold',
                      color: 'gray',
                      textAlign: 'end',
                      backgroundColor: 'transparent',
                      border: 'none',
                      cursor: 'default',
                    }}
                  />
                </Col>
              </Row>
              {/* <Divider /> */}
              <div style={{ width: '100%' }}>
                {/* <Divider></Divider> */}

                <Row gutter={[16, 16]}>
                  {/* First Column - Start Alignment */}
                  <Col span={8}>
                    <Form.Item name='upload' valuePropName='fileList'>
                      <Button
                        icon={<SendOutlined />}
                        style={{ color: '#1677FF' }}
                        onClick={handleNotification}>
                        Notify Issues To Vendor
                      </Button>
                    </Form.Item>
                  </Col>

                  {/* Second Column - Center Alignment */}
                  <Col span={8} style={{ textAlign: 'center' }}>
                    <Input
                      value='Last Validated: 02:45 pm, 30-03-2024'
                      disabled
                      style={{
                        fontWeight: 'bold',
                        color: 'black',
                        backgroundColor: 'transparent',
                        border: 'none',
                        cursor: 'default',
                        textAlign: 'center',
                      }}
                    />
                  </Col>

                  {/* Third Column - Compact Layout */}
                  <Col
                    span={8}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      gap: '10px', // Adds consistent spacing between the Select and Button
                    }}>
                    <Select
                      getPopupContainer={getPopupContainer}
                      style={{
                        fontWeight: 'bolder',
                        color: 'black',
                        width: '150px', // Fixed width for consistent layout
                      }}
                      onChange={handleDropdown1Change}
                      placeholder='Export as'>
                      <Option value='PDF'>PDF</Option>
                      <Option value='Excel'>Excel</Option>
                    </Select>
                    <Button
                      style={{
                        color: '#1677FF',
                        cursor: 'pointer',
                        fontWeight: '600',
                        textDecoration: 'underline',
                      }}
                      onClick={(evt) => downloadFile1(evt)}>
                      <DownloadOutlined />
                    </Button>
                  </Col>
                </Row>

                {/* Table Section */}
                <Table
                  dataSource={excepEmployeeRecords}
                  columns={Exceptionscolumns}
                  pagination={{ pageSize: 5 }}
                  scroll={{ x: 1500 }}
                />

                {/* Export and Download Section */}
                {/* <Row
                      gutter={[16, 16]}
                      style={{ justifyContent: 'end', marginTop: '10px' }}>
                      <Col span={12} offset={12} style={{ textAlign: 'right' }}>
                        <Select
                          getPopupContainer={getPopupContainer}
                          style={{
                            fontWeight: 'bolder',
                            color: 'black',
                          }}
                          onChange={handleDropdown1Change}
                          placeholder='Export as'>
                          <Option value='PDF'>PDF</Option>
                          <Option value='Excel'>Excel</Option>
                        </Select>
                        <Button
                          style={{ float: 'right', marginLeft: '10px' }}
                          type='primary'
                          onClick={(evt) => downloadFile1(evt)}>
                          Download
                        </Button>
                      </Col>
                    </Row> */}
              </div>
            </div>
          )}

          {currentStep === 2 && exceptionRecords && (
            <div style={{ width: '100%' }}>
              <Row
                gutter={[16, 16]}
                justify='center'
                align='middle'
                style={{
                  textAlign: 'center',
                  padding: '30px',
                  margin: '0 auto',

                  maxWidth: '900px',
                }}>
                {/* Contractor Name */}
                <Col xs={24} sm={12} md={8}>
                  {' '}
                  {/* Responsive layout */}
                  <Input
                    value={`Contractor Name: ${
                      contractorDetails?.contractorName ||
                      reportData?.data?.supplierName
                    }`} // Use template literals correctly
                    disabled
                    style={{
                      color: 'black',
                      textAlign: 'center',
                      backgroundColor: '#E3F8FF',
                      border: 'none',
                      cursor: 'default',
                      fontWeight: 'bold',
                      borderRadius: '4px',
                      padding: '8px',
                    }}
                  />
                </Col>

                <Col xs={24} sm={12} md={8}>
                  {' '}
                  <Input
                    value={`Acceptable difference(₹): ${contractorDetails.acceptableDifference}`} // Use template literals correctly
                    disabled
                    style={{
                      color: 'black',
                      textAlign: 'center',
                      backgroundColor: '#E3F8FF',
                      border: 'none',
                      cursor: 'default',
                      fontWeight: 'bold',
                      borderRadius: '4px',
                      padding: '8px',
                    }}
                  />
                </Col>

                {/* Month */}
                <Col xs={24} sm={12} md={8}>
                  {' '}
                  {/* Responsive layout */}
                  <Input
                    value={`Month: ${contractorDetails.month}`} // Use template literals correctly
                    disabled
                    style={{
                      color: 'black', // Value color
                      textAlign: 'center',
                      backgroundColor: '#E3F8FF',
                      border: 'none',
                      cursor: 'default',
                      fontWeight: 'bold', // Highlight the text
                      borderRadius: '4px', // Rounded input corners
                      padding: '8px', // Add padding for input
                    }}
                  />
                </Col>
              </Row>
              <Row
                gutter={[8, 8]} // Reduced gutter for less padding
                align='middle' // Vertically center the content
                justify='center' // Horizontally center the column content
                style={{ height: '100%' }} // Ensure row takes full height if needed
              >
                {/* Last Validated - Full Center Alignment */}
                <Col span={8}></Col>
                <Col
                  span={8}
                  style={{
                    textAlign: 'center', // Align the content inside the column
                  }}>
                  <Input
                    value='Last Validated: 05:03 pm, 06-12-2024'
                    disabled
                    style={{
                      fontWeight: 'bold',
                      color: 'black',
                      backgroundColor: 'transparent',
                      border: 'none',
                      cursor: 'default',
                      textAlign: 'center', // Ensure the text inside the input is centered
                    }}
                  />
                </Col>

                {/* Acceptable Difference and Download Controls */}
                <Col span={8} style={{ textAlign: 'right' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      gap: '10px',
                    }}>
                    <Select
                      getPopupContainer={getPopupContainer}
                      style={{
                        fontWeight: 'bolder',
                        color: 'black',
                        width: '80px',
                      }}
                      onChange={handleDropdown1Change}
                      placeholder='PDF'>
                      <Option value='PDF'>PDF</Option>
                      <Option value='Excel'>Excel</Option>
                    </Select>
                    <Button
                      type='primary'
                      style={{
                        color: '#1677FF',
                        cursor: 'pointer',
                        fontWeight: '600',
                        textDecoration: 'underline',
                        backgroundColor: 'white',
                      }}
                      onClick={(evt) => downloadFile(evt)}>
                      <DownloadOutlined /> {/* Apply bold style */}
                    </Button>
                  </div>
                </Col>
              </Row>
              <Table
                dataSource={noMatchRecords}
                columns={nomatchRecord}
                pagination={{ pageSize: 5 }}
                scroll={{ x: 1500 }}
              />
            </div>
          )}

          {/* For Issues */}
        </Modal>
      </Form>
      {/* <Divider></Divider> */}

      <Space
        direction='vertical'
        style={{ margin: '22px 12px', display: 'flex' }}>
        {/* <Table
          bordered
          dataSource={Employee1}
          columns={column}
          onRow={(record) => ({
            onClick: () => handleRowClick(record), // Row click event
          })}
          // title={Title}
          rowKey='userid'
        /> */}
        <Table
          bordered
          dataSource={Employee1}
          columns={column}
          onRow={(record) => ({
            onClick: () => handleRowClick(record), // Row click event
          })}
          rowSelection={{
            type: 'checkbox', // Enables checkbox selection
            ...rowSelection,
          }}
          rowKey='userid'
        />
        ;
      </Space>
    </>
  );
}

export default Pfreport;
